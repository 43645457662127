// media query mixins
$tablet: 768px;
$desktop: 1024px;

@mixin untilTablet {
  @media only screen and (max-width: $tablet) {
    @content;
  }
}

@mixin fromTablet {
  @media only screen and (min-width: $tablet) {
    @content;
  }
}

@mixin untilDesktop {
  @media only screen and (max-width: $desktop) {
    @content;
  }
}

@mixin fromDesktop {
  @media only screen and (min-width: $desktop) {
    @content;
  }
}

// colors
$black: #000;
$white: #fff;
$grey: #e4e4e4;
$grey-disabled: #f4f4f4;
$grey-disabled-dark: #848484;
$grey-disabled-light: $grey;
$grey-light: #f0f0f0;
$green: #00c853;
$green-darker: #00af48;
$blue: #42a5f5;
$subTitle: #00273b;

// Seasons (new colours)
$season-eco: #f0f5fa;
$season-low: #e3f2fe;
$season-mid: #c6e4fc;
$season-holiday: #8ec9f9;
$season-reg: #1d93fe;
$season-high: #0a6ab9;
$season-default: #8ec9f9;

$ticket-range: $green;
$ticket-range-start: $green;
$ticket-range-end: $green;

@mixin containerTop {
  padding: 1rem;
  transition: 0.3s;
  position: relative;
  background-color: $white;
  border: 1px solid #e4e4e4;
  border-width: 1px 0 0 0;
  border-bottom: none;
  border-radius: 16px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  @include fromDesktop {
    padding: 1.6rem;
  }
}

@mixin container {
  @include containerTop;
  border-top: 1px dashed $grey;
}

@mixin noContainer {
  padding: 0;
  border: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;

  &:before,
  &:after {
    display: none;
  }
}

@mixin containerInside {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

@mixin h2Style {
  margin: 0 0 1rem 0;
  color: #495157;
  font-size: 2.1rem;
  font-weight: 700;
}

%hotelStay {
  background-color: transparent;
}

%subTitle {
  font-size: 1.6rem;
  font-weight: 700;
  color: $subTitle;
  strong {
    font-weight: 400;
  }
}

%priceBig {
  font-size: 2.4rem;
  line-height: 1;
  font-weight: 700;
}

%priceGreen {
  color: $green;
  font-weight: 700;
}

%price {
  @extend %priceBig;
  @extend %priceGreen;
}

%pStyle {
  font-size: 1.6rem;
  line-height: 1.4;
}

%activeTick {
  border-color: $green !important;

  &::before {
    background: #00c853;
    content: "";
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    bottom: -25px;
    top: auto;
    right: 50%;
    transform: translateY(0) translateX(50%);
  }

  &::after {
    background: 0 0;
    content: "";
    width: 5px;
    height: 12px;
    border-radius: 0;
    position: absolute;
    border: 5px solid #fff;
    border-left: none;
    border-top: none;
    bottom: -15px;
    top: auto;
    right: 50%;
    transform: translateY(-53%) translateX(50%) rotate(45deg);
  }
}
